// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
// Variables
@import "variables";
// Bootstrap
@import "~bootstrap/scss/bootstrap";
// My Changes
@import "../css/app.css";
@import "../css/deciding_page.css";
@import "../css/spot.css";