/* MUDANÇA */

/* TELA DE DECIÇÃO */
.deciding-page{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 20px 0;
}


.containerDashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto !important;
    padding: 5rem 0;
    height: 100vh;
}

.containerDashboard .column {
    margin: 0 auto;
    padding: 0 2rem;
}

.containerDashboard  .card {
    padding: 30px 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 45vh;
    margin: 0;
}

.containerDashboard .card .card-title {
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 30px;
}

.containerDashboard .card p {
    color: #5a5a5a;
}



@media (max-width: 40rem) {
    .containerDashboard  {
        padding: 2rem 0;
    }

    .containerDashboard .column {
        padding: 0.5rem 0.5rem;
    }
}