body {
    font-family: 'Open Sans', sans-serif !important;
}

.logoCard {
    text-align: center;
    margin-bottom: 20px;
}

#app {
    background: #f2f2f2;
}

.takeLogin {
    padding: 50px 0;
    margin: 0 auto;
}

.login_form .card-body {
    padding: 40px 0;
    border-radius: 20px;
}

.takeBtn {
    text-align: center;
    margin-top: 15px !important;
}

.btn_default {
    text-transform: uppercase !important;
    background: #151c29 !important;
    padding: 10px 25px !important;
    font-weight: bold !important;
    border: 0 !important;
    outline: 0 !important;
    -o-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -khtml-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.btn_default:hover {
    background: #e74651 !important;
    -o-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -khtml-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.btnpesquisar {
    text-transform: uppercase !important;
    background: #151c29 !important;
    padding: 10px 25px !important;
    font-size: 1rem !important;
    font-weight: bold !important;
    border: 0 !important;
    outline: 0 !important;
    display: flex !important;
    align-items: center !important;
    -o-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -khtml-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.btnpesquisar:hover {
    background: #e74651 !important;
    -o-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -khtml-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.btnpesquisar:focus {
    box-shadow: 0 0 0 0 !important;
}

.bntlimpascampos {
    text-transform: uppercase !important;
    background: transparent !important;
    padding: 10px 25px !important;
    font-size: 0.875rem !important;
    font-weight: bold !important;
    border: 0 !important;
    outline: 0 !important;
    display: flex !important;
    color: #e74651 !important;
    width: 200px;
    align-items: center !important;
    -o-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -khtml-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.bntlimpascampos:hover {
    background: transparent !important;
    color: #151c29 !important;
    -o-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -khtml-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.bntlimpascampos:focus {
    box-shadow: 0 0 0 0 !important;
}

.titleLogin {
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}

.takeForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.takeForm .row {
    margin: 0 auto;
}

.takeForm .row label {
    font-weight: bold;
    text-transform: uppercase;
    color: #151c29;
}

.filtros {
    background: #fff !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 10px;
}

.itemClear {
    margin-top: 25px !important;
}

span.material-icons {
    margin-right: 4px;
}

.filtros .container {
    flex-wrap: wrap;
}

.filtros form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.filtros .item-group {
    margin: 0;
    margin-bottom: 20px;
}

.filtros label {
    margin-bottom: 2px;
    text-transform: uppercase;
    font-weight: bold;
    color: #151c29;
}

.filtros input,
.filtros select {
    width: 95% !important;
}

.conteudo {
    margin: 0 auto;
    margin-top: 30px;
    background: #fff;
    padding: 40px 0;
}

.table {
    border-radius: 10px;
    border: 1px solid #ededed;
    color: #151c29;
}

.table> :not(caption)>*>* {
    color: #151c29;
    text-transform: uppercase;
}

.table> :not(:first-child) {
    border-top: 2px solid #ededed !important;
}

.table>tbody {
    vertical-align: middle !important;
    font-weight: 550;
}

.table thead th a {
    -o-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -khtml-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
    position: relative;
    color: #151c29;
    top: 7px;
}

.table thead th a:hover {
    -o-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -khtml-transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
    color: #e74651;
}

.table thead tr th button {
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.table .green {
    background: #d5ffd4;
}

.table .yellow {
    background: #faffd4;
}

.table .red {
    background: #ffd4d4;
}

.table .blue {
    background: #d4f7ff;
}

.page-link {
    color: #151c29 !important;
}

.page-item.active .page-link {
    background-color: #151c29 !important;
    border-color: #151c29 !important;
    color: #fff !important;
}

.card {
    margin: 10px;
}

.container {
    display: flex;
}

.img_produto {
    margin: 10px 0;
    width: 60px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.img_produto--linha2 {
    width: 50px;
    height: 50px;
    margin: 0;
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
}

.none_style {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.filtrarValor {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.animation-element {
    opacity: 0;
    position: relative;
}

.animation-element .slide-aparece {
    -webkit-transition: all 600ms ease-out;
    -moz-transition: all 600ms ease-out;
    -o-transition: all 600ms ease-out;
    transition: all 600ms ease-out;
    opacity: 0;
    -moz-transform: translate3d(0px, 0px, 0px);
    -webkit-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate3d(0px, 0px, 0px);
}

.in-view {
    opacity: 1;
    -webkit-transition: all 500ms ease-in;
    -moz-transition: all 500ms ease-in;
    -o-transition: all 500ms ease-in;
    transition: all 500ms ease-in;
    -moz-transform: translate3d(0px, 0px, 0px);
    -webkit-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate3d(0px, 0px, 0px);
}


.moreItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
}

.aditional_details .aditional {
    border-bottom: 1px solid #eee;
    padding: 15px 0;
}

.aditional b {
    color: #151c29;
}

.moreItems .itemChild b {
    color: #151c29;
}

.moreItems .itemChild {
    margin-bottom: 20px;
}
